// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { withchangeBackgroundColor } from "../codeFile/M_5.tsx";
const RichTextWithchangeBackgroundColor = withchangeBackgroundColor(RichText);
const MotionDivWithchangeBackgroundColor = withchangeBackgroundColor(motion.div);

const cycleOrder = ["WbF60i3ui"];

const variantClassNames = {WbF60i3ui: "framer-v-m3keey"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, B0qH3Qn4l: title ?? props.B0qH3Qn4l ?? "-Dizajn pre digitálny vek-"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, B0qH3Qn4l, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WbF60i3ui", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-dNEf9", classNames)} style={{display: "contents"}}>
<Transition value={transition}><MotionDivWithchangeBackgroundColor {...restProps} className={cx("framer-m3keey", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WbF60i3ui"} ref={ref} style={{...style}}><RichTextWithchangeBackgroundColor __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItcmVndWxhcg==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-letter-spacing": "9.70745px", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}><motion.span style={{"--framer-font-size": "20.0982px"}}>-Dizajn pre digitálny vek-</motion.span><motion.span style={{"--framer-font-size": "20.0982px"}}><motion.br/></motion.span></motion.p></React.Fragment>} className={"framer-4u747r"} data-framer-name={"Podnadpis"} fonts={["GF;Inter-regular"]} layoutDependency={layoutDependency} layoutId={"hvficF43_"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={B0qH3Qn4l} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></MotionDivWithchangeBackgroundColor></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-dNEf9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dNEf9 .framer-16uub45 { display: block; }", ".framer-dNEf9 .framer-m3keey { height: 30px; overflow: hidden; position: relative; width: 571px; }", ".framer-dNEf9 .framer-4u747r { -webkit-user-select: none; flex: none; height: auto; left: 0px; pointer-events: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 571
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"B0qH3Qn4l":"title"}
 * @framerImmutableVariables false
 */
const FramerOflderBNm: React.ComponentType<Props> = withCSS(Component, css, "framer-dNEf9") as typeof Component;
export default FramerOflderBNm;

FramerOflderBNm.displayName = "Podnadpis";

FramerOflderBNm.defaultProps = {height: 30, width: 571};

addPropertyControls(FramerOflderBNm, {B0qH3Qn4l: {defaultValue: "-Dizajn pre digitálny vek-", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerOflderBNm, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/OflderBNm:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf", weight: "400"}])